.navigationTop li {
  /* float: right;
  margin-left: 34px;
  position: relative; */
}
.username {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  cursor: pointer;
  white-space: nowrap;
}
.dropdown-menu {
  min-width: 100px;
  cursor: pointer;
}
.dropdown-menu > li > a {
  color: #337ab7;
  padding: 0.5rem;
  font-size: 14px;
}
.dropdown-menu > li > a:hover {
  text-decoration: underline;
  background: none;
}
.btn .caret {
  margin-left: 0.5rem;
  /* margin-top: 0.7rem; */
  color: #fff;
}

#menu-mobile .caret {
  display: none;
}

.navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li > a:hover {
  color: #2574d4;
  background-color: transparent;
}
