.menuMobile {
  padding: 0;
  .dropdown {
    margin-left: 0;
  }
  &.btn-toolbar--custome {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    div {
      margin: 0px;
      cursor: pointer;
      width: 100%;
    }
  }
  .menuItem {
    color: rgb(255, 255, 255);
    margin-left: 10px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  .toggle {
    border: none;
    background: none;
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    i {
      color: #fff;
      font-size: 1.25rem;
    }
  }
  .navMobile {
    background: black;
    position: fixed;
    top: 69px;
    min-width: 100%;
    padding: 0px;
    overflow-y: auto;
    height: auto;
    max-height: calc(100vh - 70px);
  }
  .link {
    &:not(.v-nav--border) {
      margin-top: -4px !important;
    }
    a {
      color: #092f7d;
      &:hover {
        background: #2574d4;
        color: #fff;
        text-decoration: none;
      }
    }
  }
  .border {
    a {
      border-bottom: 1px solid #292929;
    }
  }
  .li_item {
    margin: 0;
    float: none;
    cursor: pointer;
    text-align: left;
  }
  .locales {
    display: flex;
    justify-content: center;
  }
  .nav {
    background: #2574d4;
    text-align: center;
    text-transform: uppercase;
  }
}

@media (orientation: landscape) and (max-width: 992px) {
  .menuMobile {
    .navMobile {
      height: calc(100vh - 70px);
    }
  }
}
