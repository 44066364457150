.txt-search {
  width: 220px;
  height: 30px;
  font-size: 18px;
  color: #ccc;
  padding: 5px 30px 5px 10px;
  right: 10px;
  top: -4px;
  z-index: 9;
  background: transparent;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
}

.icon {
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}

// config for project W
// by using wrapper class
.v-nav--search.wrapper {
  background-color: #dadada;
  border-radius: 50%;
  /* padding: 5px 12px; */
  cursor: pointer;
  margin-right: 0 !important;
  & i {
    color: #555555;
    /* margin-left: -7px; */
  }
}

@media (min-width: 768px) {
  .v-nav--search.wrapper {
    /* padding: 5px 14px; */
  }
  li.wrapper .fa {
    font-size: 1rem !important;
  }
}
