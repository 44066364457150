.header {
  /* position: absolute; */
  /* left: 0;
  top: 0;
  z-index: 1000; */
  width: 100%;
  height: var(--hHeader);
  position: sticky;
  top: 0;
  z-index: 99;
  ul,
  ol {
    list-style-type: none;
  }
}
.v-box {
  .header {
    position: static;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: var(--hHeader);
    min-height: var(--hHeader);
  }
  &.v-blockRegionSubscription {
    .header {
      display: none;
    }
  }
}

.navigationTop {
  .btn {
    padding: 0;
  }
  .btn-toolbar {
    margin-left: 0;
  }
  .open > .dropdown-toggle.btn-default {
    &:focus,
    &:hover {
      background-color: transparent;
      opacity: 0.8;
    }
  }
  .v-nav--listMenu {
    display: flex;
    column-gap: 10px;
  }
  .slash {
    display: inline-block;
    border-left: 1px solid #fff;
    height: 18px;
  }
}

.navigationTop .v-nav--listMenu {
  position: unset;
}

.navigationTop .username {
  font-size: 1rem;
}
.navigationTop ul li {
  /* margin-left: 0.5rem; */
}
.navigationTop ul li {
  .fa {
    font-size: 18px;
  }
  &:not(.is_open) .fa-search {
    font-size: 8px;
  }
}
.navigationTop ul > li.v-search {
  width: 18px;
  height: 18px;
  top: 50%;
  transition: all 0.25s;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  @media (min-width: 768px) {
    width: 31px;
    height: 31px;
  }
}

.navigationTop ul > li.v-search.is_open {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  min-width: 60%;
  /* top: 100%; */
  right: 15px;
  padding-right: 1rem;
  margin: 0;
  width: auto;
  height: auto;
  inset-block: auto;
}
.navigationTop ul > li.v-search.is_open input {
  flex-grow: 1;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
  .navigationTop ul > li.v-search.is_open {
    right: 45px;
  }
  .navigationTop .username {
    font-size: 1.125rem;
  }
  /* .navigationTop ul li {
    margin-left: 1.25rem;
  } */

  .navigationTop {
    margin-left: 2rem;
    ul li {
      .fa {
        font-size: 1.5rem;
      }
      &:not(.is_open) .fa-search {
        font-size: 13px;
      }
    }
    .slash {
      /* display: none; */
      height: 20px;
    }
    .v-nav--listMenu {
      column-gap: 15px;
    }
  }
}
@media (min-width: 992px) {
  .v-landding {
    /* .header + div {
      margin-top: 0;
      &:before {
        content: none;
      }
    } */
  }
  .navigationTop .v-nav--listMenu {
    position: relative;
  }
  .navigationTop ul > li.v-search.is_open {
    position: absolute;
    width: auto;
    padding: 0.25rem 0;
    padding-right: 1rem;
    transform: none;
    top: 0;
    right: 95%;
  }
}
